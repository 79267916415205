import { Icon, LegacyStack, Page } from "@shopify/polaris";
import { ExternalSmallMinor, InfoMinor } from "@shopify/polaris-icons";
import { ISmartrrBundleConfig } from "@smartrr/shared/entities/SellingPlanGroup";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";

import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import { typedFrontendVendorApi } from "@vendor-app/utils/typedFrontendVendorApi";

import { BundlesContainer } from "./components/BundlesContainer";
import { Spinner } from "../components/elements/Spinner";
import { LearnMoreAboutLink, LearnMoreIconWrapper, LearnMoreLinkContainer } from "../components/elements/styles";
const BundlesConfigContainer = styled.div`
  & > * {
    width: 100%;
  }

  .docs-link {
    bottom: 0;
    left: 50%;
    position: absolute;
    transform: translate(-50%);
  }
`;

export function AdminBundlesConfigRoute(): JSX.Element {
  const { addToast } = useToast();
  const [bundlesLoading, setBundlesLoading] = useState(true);
  const [bundleConfigs, setBundleConfigs] = useState<ISmartrrBundleConfig[]>([]);

  const fetchBundles = useCallback(async () => {
    setBundlesLoading(true);
    const bundleConfigs = await typedFrontendVendorApi.getReq("/bundle-config");
    if (bundleConfigs.type === "error") {
      return addToast("Error fetching bundle configurations");
    }
    setBundleConfigs(bundleConfigs.body);
    setBundlesLoading(false);
  }, []);

  useEffect(() => {
    fetchBundles();
  }, []);

  if (bundlesLoading) {
    return <Spinner accessibilityLabel="Spinner" />;
  }

  return (
    <React.Fragment>
      <Page narrowWidth>
        <BundlesConfigContainer>
          <div style={{ paddingTop: "30px" }}>
            <BundlesContainer bundleConfigs={bundleConfigs} />
          </div>
          <div className="docs-link">
            <LegacyStack distribution="center" spacing="tight">
              <Icon source={InfoMinor} color="highlight" />
              <LearnMoreLinkContainer>
                Learn more about&nbsp;
                <LearnMoreAboutLink
                  href="https://help.smartrr.com/docs/support/admin-portal/what-are-bundles"
                  rel="noreferrer"
                  target="_blank"
                >
                  bundles
                  <LearnMoreIconWrapper>
                    <Icon source={ExternalSmallMinor} color="base" />
                  </LearnMoreIconWrapper>
                </LearnMoreAboutLink>
              </LearnMoreLinkContainer>
            </LegacyStack>
          </div>
        </BundlesConfigContainer>
      </Page>
    </React.Fragment>
  );
}
